import fetch from "auth/FetchInterceptor";

const PickupRequest = {};

PickupRequest.get = function (payload) {
  return fetch({
    url:
      "/reports/pickup-request?" +
      "startdate=" +
      payload.startDate +
      "&" +
      "enddate=" +
      payload.endDate +
      "&" +
      "agency=" + payload.agency + "&department=null",
    method: "get",
  });
};

export default PickupRequest;
