import axios from "axios";
import {env, LOGIS_BASE_URL} from "../configs/EnvironmentConfig";
import {AUTH_TOKEN, LOGIS_AUTH_TOKEN} from "../redux/constants/Auth";
import moment from "moment/moment";

const logisPickUp = {};

    logisPickUp.login = function (payload){
        return axios({
            url: LOGIS_BASE_URL + '/login',
            method: 'POST',

            data : payload
        });
    }

    logisPickUp.getLocations = function (payload) {
       return  axios({
            url: LOGIS_BASE_URL + '/getlocations',
            method: 'POST',
            data : payload
        });
    }

    logisPickUp.createPickupRequest = function (payload) {
       return axios({
            url: LOGIS_BASE_URL + '/createpickuprequest',
            method: 'POST',
            data : payload
        });
    }
export default logisPickUp;


