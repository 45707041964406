import {
    CURRENT_USER,
    LOGIN_EXPIRATION_TIME,
    LOGIS_AUTH_TOKEN,
    LOGIS_LOCATION_LIST, LOGIS_PASSWORD,
    LOGIS_TOKEN_EXPIRATION_TIME, LOGIS_USERNAME
} from "../redux/constants/Auth";
import moment from "moment";
import {LOGIS_TOKEN_EXPIRATION_IN_HOUR, TOKEN_EXPIRATION_TIME} from "../configs/AppConfig";
import  LogisPickUp from "services/CreateLogisPickup";
import {message} from "antd";
import {ShowNotification} from "../components/shared-components/NotificationApi/ShowNotification";

export  const handleLogisPickUp = async () => {
    const LOGIS_TOKEN = localStorage.getItem(LOGIS_AUTH_TOKEN);
    const tokenExpired = new Date(localStorage.getItem(LOGIS_TOKEN_EXPIRATION_TIME)).getTime() > new Date().getTime() && localStorage.getItem(LOGIS_TOKEN_EXPIRATION_TIME) !== null

    if (tokenExpired || !LOGIS_TOKEN) {
        const loginResult = await login();
        if(loginResult.data.token){
            localStorage.setItem(LOGIS_AUTH_TOKEN, loginResult.data.token);
            localStorage.setItem(LOGIS_TOKEN_EXPIRATION_TIME, moment().add(LOGIS_TOKEN_EXPIRATION_IN_HOUR, 'hours').toString());
        }
    }
   return  await handlePickup();
}

async function handlePickup(){
    const LOGIS_TOKEN = localStorage.getItem(LOGIS_AUTH_TOKEN);
    const locations = await getLocations(LOGIS_TOKEN);
    localStorage.setItem(LOGIS_LOCATION_LIST, JSON.stringify(locations.data.locations));
    const currentUserLocation = getCurrentUserLocation();
    const pickUpRequestResult = await createPickupRequest({
        "token" : LOGIS_TOKEN,
        "location" : currentUserLocation.location,
        "date" : moment().format("YYYY-MM-DDTHH:MM:SS"),
        "pickup" : true,
        "material" : false,
        "urgent" : false,
        "isprivatevisit" : false,
        "private_name" :  "",
        "private_address" :  "",
        "private_city" : "",
        "private_country" : "",
        "private_phone" : "",
        "private_mobile" : "",
        "remark" : "For testing"
    });
    if(pickUpRequestResult.status === 200){
        ShowNotification("success", "bottomRight", "Pickup Request sent");
    } else {
        ShowNotification("error", "bottomRight", "Error sending pickup request");
    }


    return pickUpRequestResult.data;
}


async function login() {
    return LogisPickUp.login({
        email: LOGIS_USERNAME,
        password: LOGIS_PASSWORD
    });
}


async function getLocations(LOGIS_TOKEN){
    return LogisPickUp.getLocations(
        {
            "location": "",
            "token": LOGIS_TOKEN
        }
    );
}


async function createPickupRequest(data){
    return LogisPickUp.createPickupRequest(data);
}


function getCurrentUserLocation(){
    const locations =  JSON.parse(localStorage.getItem(LOGIS_LOCATION_LIST));
    const currentUser =JSON.parse(localStorage.getItem(CURRENT_USER));
    let tempLocation = {};

    locations.forEach(location =>{
            if(location.name === currentUser.name){
                tempLocation = location;
            }
    });
   return tempLocation;
}
